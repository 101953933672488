import React from 'react';
import styled from '@emotion/styled';
import Publication from './Publication';
import Container from '../Container';
import { HeadingMinor } from '../Headings';
import { mq } from '../../styles/functions/mq';
import { COLORS } from '../../styles/settings/global';

// #region styles
const SPublicationList = styled.section`
  position: relative;
  padding: 80px 30px;

  ${mq.tablet} {
    padding: 60px 120px;
  }

  ${mq.landscape} {
  }

  ${mq.desktop} {
    padding: 120px 180px;
  }
`;

const SDecoration = styled.div`
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 13px;
  background-color: ${COLORS.LIGHT};
`;
// endregion

const PublicationList = ({ heading, items, onDownloadClick }) => (
  <Container>
    <SPublicationList>
      <SDecoration />
      <HeadingMinor>{heading}</HeadingMinor>
      {items.map((item, index) => {
        const isDownloadable = item.button.type === 'download';
        const buttonLabel =
          item.button.text || isDownloadable ? 'Download' : 'Read More';
        return (
          <Publication
            key={index}
            title={item.title}
            description={item.descriptionShort}
            buttonLabel={buttonLabel}
            onClick={() => {
              if (isDownloadable) {
                onDownloadClick({
                  category: item.title,
                  title: item.title,
                  description: '',
                  url: item.button.file?.publicURL,
                });
              } else {
                window.open(item.button.url, '_blank');
              }
            }}
          />
        );
      })}
    </SPublicationList>
  </Container>
);

export default PublicationList;

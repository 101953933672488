import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { mq } from '../../styles/functions/mq';
import Button from '../Button';
import Container from '../Container';
import { Heading3 } from '../Headings';

// #region styles
const SPublication = styled.section`
  margin: 40px 0;

  ${mq.tablet} {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
  }
`;

const SContent = styled.div``;

const SDescription = styled.div`
  max-width: 480px;
  margin-top: 20px;
`;

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${mq.largePhone} {
    justify-content: flex-start;
  }

  ${mq.tablet} {
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 100px;
  }
`;

const CButton = css`
  margin-top: 40px;

  ${mq.tablet} {
    margin-top: 5px;
  }
`;
// endregion

const Publication = ({ title, description, buttonLabel, onClick }) => (
  <Container>
    <SPublication>
      <SContent>
        {title && <Heading3>{title}</Heading3>}
        {description && <SDescription>{description}</SDescription>}
      </SContent>

      <SButtonWrapper>
        <Button gap="side" onClick={onClick} cssStyle={CButton}>
          {buttonLabel}
        </Button>
      </SButtonWrapper>
    </SPublication>
  </Container>
);

export default Publication;

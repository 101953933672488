import React from 'react';
import { graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Layout from '../components/Layout';
import Podcast from '../components/Podcast';
import DownloadModal from '../components/DownloadModal';
import PublicationList from '../components/publications/PublicationList';
import Hero from '../components/Hero';

class PublicationsPage extends React.Component {
  state = {
    modal: {
      active: false,
      category: '',
      title: '',
      description: '',
      url: '',
    },
  };

  render = () => {
    const { data, location } = this.props;
    const { modal } = this.state;
    const { publications } = data.publicationsYaml;
    const { meta, hero } = data.publicationsPageYaml;
    const publicationsProcurement = publications.filter(
      (pub) => pub.showOnPublicationsPage === 'procurement',
    );
    const publicationsOriginalResearch = publications.filter(
      (pub) => pub.showOnPublicationsPage === 'originalResearch',
    );

    return (
      <Layout meta={meta} location={location}>
        <DownloadModal
          active={modal.active}
          category={modal.category}
          title={modal.title}
          description={modal.description}
          url={modal.url}
          data={data}
          onClose={() => {
            this.setState({
              modal: {
                active: false,
              },
            });
          }}
        />

        <Hero
          title={hero.title}
          description={hero.description}
          image={hero.image?.publicURL}
          imageAlt={hero.imageAlt}
          decorationImage={hero.decorationImage?.publicURL}
          decorationImageAlt={hero.decorationImageAlt}
        />

        <PublicationList
          heading="Procurement"
          items={publicationsProcurement}
          onDownloadClick={({ category, title, description, url }) => {
            this.setState({
              modal: {
                active: true,
                category,
                title,
                description,
                url,
              },
            });
            trackCustomEvent({
              category: 'publication',
              action: 'request',
              label: title,
            });
          }}
        />

        <Podcast podcast={data.podcastYaml} />

        <PublicationList
          heading="Original research"
          items={publicationsOriginalResearch}
          onDownloadClick={({ category, title, description, url }) => {
            this.setState({
              modal: {
                active: true,
                category,
                title,
                description,
                url,
              },
            });
            trackCustomEvent({
              category: 'publication',
              action: 'request',
              label: title,
            });
          }}
        />
      </Layout>
    );
  };
}

export default PublicationsPage;

// #region graphql
export const pageQuery = graphql`
  query PublicationsPage {
    publicationsPageYaml {
      meta {
        title
        description
      }
      hero {
        title
        description
        image {
          publicURL
        }
        imageAlt
        decorationImage {
          publicURL
        }
        decorationImageAlt
      }
    }

    podcastYaml {
      title
      image {
        publicURL
      }
      imageAlt
      description {
        html
      }
      button {
        text
        url
        newTab
      }
      applePodcastsLink
      stitcherLink
    }

    publicationsYaml {
      publications {
        title
        descriptionShort
        button {
          type
          file {
            publicURL
          }
          url
          text
        }
        showOnPublicationsPage
      }
    }
  }
`;
// endregion
